import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-hot-toast';
import EditIcon from "@mui/icons-material/EditRounded";
import { Switch } from '@mui/material';
import { generateRandomString } from '../header';
import TrashIcon from "@mui/icons-material/DeleteRounded";


const parseTimeFromString = (timeString) => {
  if (!timeString) return { hour: '12', minute: '00', period: 'AM' };
  
  const date = new Date(timeString);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  let period = 'AM';

  if (hours >= 12) {
    period = 'PM';
    if (hours > 12) hours -= 12;
  } else if (hours === 0) {
    hours = 12;
  }

  return {
    hour: hours.toString(),
    minute: minutes.toString().padStart(2, '0'),
    period
  };
};

// Create local time string from time components
const createTimeString = (hour, minute, period) => {
  let hours = parseInt(hour, 10);
  const mins = parseInt(minute, 10);

  // Convert 12-hour format to 24-hour format
  if (period === 'PM' && hours !== 12) hours += 12;
  if (period === 'AM' && hours === 12) hours = 0;

  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMins = String(mins).padStart(2, '0');

  // Create time string in local format: YYYY-MM-DDTHH:mm:00
  return `${year}-${month}-${day}T${formattedHours}:${formattedMins}:00`;
};


const RemindersTab = ({ user }) => {
  const [view, setView] = useState('list'); // 'list' or 'form'
  const [reminders, setReminders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingReminder, setEditingReminder] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteReminderId, setDeleteReminderId] = useState(null);

  // Form State
  const [formData, setFormData] = useState({
    active: true,
    deviceId: user.id,
    reminderType: 'scheduled',
    message: '',
    repeatValue: '[]',
    reminderTime: new Date().toISOString()
  });

  const [timeValues, setTimeValues] = useState({
    hour: '12',
    minute: '00',
    period: 'AM'
  });

  const [checkedDays, setCheckedDays] = useState({
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    Sunday: false
  });

  const hours = Array.from({ length: 12 }, (_, i) => (i + 1).toString());
  const minutes = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'));
  const periods = ['AM', 'PM'];

  const [existingImage, setExistingImage] = useState(null); 
  const [isImageDeleted, setIsImageDeleted] = useState(false); 
  const [selectedImage, setSelectedImage] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    fetchReminders();
  }, []);

  useEffect(() => {
    if (editingReminder) {
      setFormData({
        active: editingReminder.active,
        deviceId: user.id,
        reminderType: editingReminder.reminderType,
        message: editingReminder.message,
        repeatValue: editingReminder.repeatValue,
        reminderTime: editingReminder.reminderTime
      });
  
      // Convert UTC to local time for display
      const localTime = parseTimeFromString(editingReminder.reminderTime);
      setTimeValues(localTime);

  
      // 设置图片数据
      if (editingReminder.wallpapers && editingReminder.wallpapers.length > 0) {
        setExistingImage(editingReminder.wallpapers[0]); // 假设使用第一张图片
        setIsImageDeleted(false);
        setSelectedImage(null);
      }
  
      // 设置重复日期
      if (editingReminder.repeatValue && editingReminder.repeatValue !== '[]') {
        try {
          const days = JSON.parse(editingReminder.repeatValue);
          const newCheckedDays = { ...checkedDays };
          const daysMap = {
            1: 'Monday',
            2: 'Tuesday',
            3: 'Wednesday',
            4: 'Thursday',
            5: 'Friday',
            6: 'Saturday',
            7: 'Sunday'
          };
          days.forEach(day => {
            newCheckedDays[daysMap[day]] = true;
          });
          setCheckedDays(newCheckedDays);
        } catch (e) {
          console.error('Error parsing repeat days:', e);
        }
      }
    }
  }, [editingReminder]);

  // 在编辑模式下加载图片
  useEffect(() => {
    if (editingReminder && editingReminder.image) {
      // 确保使用正确的图片数据结构
      setExistingImage({
        id: editingReminder.image.id,
        fileName: editingReminder.image.fileName
      });
      setIsImageDeleted(false);
      setSelectedImage(null);
    } else {
      setExistingImage(null);
      setIsImageDeleted(false);
      setSelectedImage(null);
    }
  }, [editingReminder]);

  const fetchReminders = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_REMINDER_API_URL}/get_all_reminders/${user.id}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          credentials: 'include',
        }
      );

      if (response.ok) {
        const data = await response.json();
        setReminders(data);
      } else {
        console.error('Failed to fetch reminders');
        toast.error('Failed to fetch reminders');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred while fetching reminders');
    } finally {
      setLoading(false);
    }
  };

  const formatTime = (timeString) => {
    const date = new Date(timeString);
    return date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  const formatDate = (timeString) => {
    const date = new Date(timeString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const formatRepeatDays = (repeatValue) => {
    if (!repeatValue || repeatValue === '[]') {
      return null;
    }

    try {
      const repeatDays = JSON.parse(repeatValue);
      if (!Array.isArray(repeatDays) || repeatDays.length === 0) {
        return null;
      }

      const daysMap = {
        1: 'Mon',
        2: 'Tue',
        3: 'Wed',
        4: 'Thu',
        5: 'Fri',
        6: 'Sat',
        7: 'Sun'
      };

      return repeatDays
        .sort((a, b) => a - b)
        .map(day => daysMap[day])
        .join(', ');
    } catch (e) {
      console.error('Error parsing repeatValue:', e);
      return null;
    }
  };

  const handleTimeChange = (field, value) => {
    const newTimeValues = { ...timeValues, [field]: value };
    setTimeValues(newTimeValues);
    
    // Create local time string
    const timeString = createTimeString(
      newTimeValues.hour,
      newTimeValues.minute,
      newTimeValues.period
    );
    
    setFormData(prev => ({
      ...prev,
      reminderTime: timeString
    }));
  };


  const handleDayChange = (day) => {
    const newCheckedDays = {
      ...checkedDays,
      [day]: !checkedDays[day]
    };
    setCheckedDays(newCheckedDays);

    const daysMap = {
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
      Sunday: 7
    };

    const repeatDays = Object.entries(newCheckedDays)
      .filter(([_, checked]) => checked)
      .map(([day]) => daysMap[day]);

    setFormData(prev => ({
      ...prev,
      repeatValue: JSON.stringify(repeatDays)
    }));
  };

  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 10 * 1024 * 1024) { // 10MB limit
        toast.error('Image size should be less than 10MB');
        return;
      }
      
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage({
          file: file,
          preview: reader.result
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    setShowDeleteDialog(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleRemoveExistingImage = () => {
    setIsImageDeleted(true);
    setExistingImage(null);
    setShowDeleteDialog(false);
  };

// 修改后的图片上传函数
const handleUploadImage = async (reminderId) => {
  if (!selectedImage || !reminderId) return false;

  const formData = new FormData();
  formData.append('file', selectedImage.file);

  try {
    const response = await fetch(
      `${process.env.REACT_APP_REMINDER_API_URL}/add_image?Id=${reminderId}`, // 将Id作为URL参数传递
      {
        method: 'POST',
        headers: {
          'portalscope': 'b3NMWOVzfdRUjrW',
          'validatereq': generateRandomString(30)
        },
        body: formData,
        credentials: 'include',
      }
    );

    if (!response.ok) {
      throw new Error(`Failed to upload image: ${response.status}`);
    }
    return true;
  } catch (error) {
    console.error('Error uploading image:', error);
    toast.error('Failed to upload image');
    return false;
  }
};

// 修改后的图片删除函数
const handleDeleteImage = async (reminderId, wallpaperId) => {
  if (!reminderId || !wallpaperId) {
    console.error('Missing required parameters for image deletion');
    throw new Error('Missing required parameters');
  }

  try {
    console.log('Deleting image with params:', {
      reminderId,
      wallpaperId
    });
    
    // 构建带查询参数的 URL
    const url = new URL(`${process.env.REACT_APP_REMINDER_API_URL}/delete_image`);
    url.searchParams.append('Id', reminderId);
    url.searchParams.append('wallpaper_id', wallpaperId);

    const response = await fetch(
      url.toString(),
      {
        method: 'DELETE',
        headers: {
          'portalscope': 'b3NMWOVzfdRUjrW',
          'validatereq': generateRandomString(30)
        },
        credentials: 'include',
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Delete image error:', errorData);
      throw new Error(errorData.title || 'Failed to delete image');
    }

    return true;
  } catch (error) {
    console.error('Error deleting image:', error);
    throw error;
  }
};

  
  const handleSave = async () => {
    if (!formData.message.trim()) {
      toast.error('Please enter a message');
      return;
    }
  
    if (formData.reminderType === 'repeating' && formData.repeatValue === '[]') {
      toast.error('Please select at least one day for repeating reminders');
      return;
    }
  
    
  
    try {
      // 1. 如果是编辑模式且标记了删除现有图片，先删除图片
      if (editingReminder && isImageDeleted && editingReminder.image) {
        try {
          await handleDeleteImage(
            editingReminder.id,  // 直接传递GUID，不做toString转换
            editingReminder.image.id  // 假设图片对象中有 id 字段
          );
        } catch (error) {
          console.error('Failed to delete image:', error);
          toast.error('Failed to delete existing image');
          return;
        }
      }
  

      // 定义 URL 和请求体
    const url = editingReminder 
    ? `${process.env.REACT_APP_REMINDER_API_URL}` 
    : `${process.env.REACT_APP_REMINDER_API_URL}`;

  const requestBody = {
    ...formData,
    ...(editingReminder ? { id: editingReminder.id } : {})
  };


      // 2. 更新或创建提醒
      const response = await fetch(url, {
        method: editingReminder ? 'PUT' : 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'portalscope': 'b3NMWOVzfdRUjrW',
          'validatereq': generateRandomString(30)
        },
        credentials: 'include',
        body: JSON.stringify(requestBody)
      });

      if (response.ok) {
        const reminderData = await response.json();
        
        // 3. 如果有新图片要上传
        if (selectedImage) {
          const uploadSuccess = await handleUploadImage(reminderData.id);
          if (!uploadSuccess) {
            toast.error('Reminder saved but failed to upload image');
            return;
          }
        }
  
        toast.success(editingReminder ? 'Reminder updated successfully!' : 'Reminder added successfully!');
        fetchReminders();
        handleCancel();
      } else {
        toast.error(editingReminder ? 'Failed to update reminder' : 'Failed to add reminder');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred while saving the reminder');
    }
  };


  const handleCancel = () => {
    setView('list');
    setEditingReminder(null);
    setFormData({
      active: true,
      deviceId: user.id,
      reminderType: 'scheduled',
      message: '',
      repeatValue: '[]',
      reminderTime: new Date().toISOString()
    });
    setTimeValues({
      hour: '12',
      minute: '00',
      period: 'AM'
    });
    setCheckedDays({
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false
    });

  };
  const handleDeleteReminderClick = (id, e) => {
    e.stopPropagation(); // Prevent row click event
    setDeleteReminderId(id);
    setShowDeleteModal(true);
  };

  const handleDeleteReminderCancel = () => {
    setShowDeleteModal(false);
    setDeleteReminderId(null);
  };

  const handleDeleteReminderConfirm = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_REMINDER_API_URL}/${deleteReminderId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          credentials: 'include',
        }
      );

      if (response.ok) {
        setReminders(reminders.filter(reminder => reminder.id !== deleteReminderId));
        setShowDeleteModal(false);
        toast.success("Reminder deleted successfully!");
      } else {
        toast.error('Failed to delete reminder. Please try again');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred while deleting the reminder');
    }
  };

  const renderDeleteModal = () => (
    showDeleteModal && (
      <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Reminder</h5>
              <button type="button" className="close" onClick={handleDeleteReminderCancel}>
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to delete this reminder?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={handleDeleteReminderCancel}>
                Cancel
              </button>
              <button type="button" className="btn btn-danger" onClick={handleDeleteReminderConfirm}>
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );

  const getScheduleDisplay = (reminder) => {
    const localTime = parseTimeFromString(reminder.reminderTime);
    
    if (reminder.reminderType === 'repeating') {
      const days = formatRepeatDays(reminder.repeatValue);
      return `${localTime.hour}:${localTime.minute} ${localTime.period} on ${days}`;
    } else {
      const dateStr = formatDate(reminder.reminderTime);
      return `${localTime.hour}:${localTime.minute} ${localTime.period} on ${dateStr}`;
    }
  };

   
   const renderImageUploadSection = () => (
    <div className="form-group" style={{ marginBottom: 20 }}>
      {existingImage && !isImageDeleted && (
        <div style={{ marginTop: 15 }}>
          <img
            src={existingImage.fileName}
            alt="Current"
            style={{ 
              maxWidth: '200px', 
              maxHeight: '200px', 
              cursor: 'pointer',
              border: '1px solid #ddd',
              borderRadius: '4px',
              padding: '5px'
            }}
            onClick={() => setShowDeleteDialog(true)}
          />
          <div style={{ marginTop: 5 }}>
            <small style={{ color: '#666' }}>
              Click image to remove
            </small>
          </div>
        </div>
      )}

      {selectedImage && (
        <div style={{ marginTop: 15 }}>
          <img
            src={selectedImage.preview}
            alt="Preview"
            style={{ 
              maxWidth: '200px', 
              maxHeight: '200px', 
              cursor: 'pointer',
              border: '1px solid #ddd',
              borderRadius: '4px',
              padding: '5px'
            }}
            onClick={() => setShowDeleteDialog(true)}
          />
          <div style={{ marginTop: 5 }}>
            <small style={{ color: '#666' }}>
              Click image to remove
            </small>
          </div>
        </div>
      )}

      {(!existingImage || isImageDeleted) && !selectedImage && (
        <div style={{ marginTop: 10 }}>
          <input
            type="file"
            ref={fileInputRef}
            accept="image/*"
            onChange={handleImageSelect}
            style={{ display: 'none' }}
          />
          <button
            type="button"
            className="btn cur-p btn-primary"
            onClick={() => fileInputRef.current.click()}
            style={{ marginRight: 10 }}
          >
            Attach Image
          </button>
          <small style={{ color: '#666' }}>Maximum size: 10MB</small>
        </div>
      )}
    </div>
  );
  const renderDeleteDialog = () => (
    showDeleteDialog && (
      <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Remove Image</h5>
              <button type="button" className="close" onClick={() => setShowDeleteDialog(false)}>
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to remove this image?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => setShowDeleteDialog(false)}>Cancel</button>
              <button 
                type="button" 
                className="btn btn-danger" 
                onClick={() => {
                  if (existingImage && !isImageDeleted) {
                    handleRemoveExistingImage();
                  } else {
                    handleRemoveImage();
                  }
                }}
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
  return (
    <div>
      {view === 'list' ? (
        <>
          <div style={{ display: 'block', marginBottom: '20px' }}>
            <button
              type="button"
              className="btn btn-outline-info btn-lg px-4 me-sm-3 fw-bold"
              style={{ marginRight: '10px' }}
              onClick={() => setView('form')}
            >
              + Add New Reminder
            </button>
          </div>

          <table className="table table-hover">
            <caption style={{ captionSide: 'top', textAlign: 'center', marginRight: '160px' }}>
              <h3>Reminders</h3>
            </caption>
            <thead>
              <tr>
                <th>Message</th>
                <th>Type</th>
                <th>Schedule</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="5" className="text-center">
                    Loading reminders...
                  </td>
                </tr>
              ) : reminders.length === 0 ? (
                <tr>
                  <td colSpan="5" className="text-center">
                    No reminders found
                  </td>
                </tr>
              ) : (
                reminders.map((reminder) => (
                  <tr key={reminder.id}>
                    <td style={{ whiteSpace: 'pre-line' }}>{reminder.message}</td>
                    <td>{reminder.reminderType === 'repeating' ? 'Weekly' : 'One Time'}</td>
                    <td>{getScheduleDisplay(reminder)}</td>
                    <td>
                      <span 
                        
                        style={{ fontSize: '0.8rem', padding: '0.4em 0.8em' }}
                      >
                        {reminder.active ? 'Active' : 'Inactive'}
                      </span>
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      <EditIcon
                        sx={{
                          fontSize: "1rem",
                          color: "black",
                          marginRight: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setEditingReminder(reminder);
                          setView('form');
                        }}
                      />
                       <TrashIcon
                        sx={{
                          fontSize: "1rem",
                          color: "black",
                          cursor: "pointer",
                        }}
                        onClick={(e) => handleDeleteReminderClick(reminder.id, e)}
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          {renderDeleteModal()}
        </>
      ) : (
        <div className="container text-left">
          <h3 className="mb-4">{editingReminder ? 'Edit Reminder' : 'New Reminder'}</h3>
          
          <div className="row mb-4">
            <div className="col col-sm-3 align-items-center d-flex">
              <p className="mb-0">Enable Reminder</p>
            </div>
            <div className="col-sm-9 align-items-center d-flex">
              <Switch
                checked={formData.active}
                onChange={(e) => setFormData(prev => ({ ...prev, active: e.target.checked }))}
              />
            </div>
          </div>

          <div className="row mb-4">
            <div className="col col-sm-3">
              <p className="mb-0">Reminder Type</p>
            </div>
            <div className="col-sm-9">
              <div className="btn-group" role="group">
                <button
                  type="button"
                  className={`btn ${formData.reminderType === 'scheduled' ? 'btn-dark' : 'btn-outline-dark'}`}
                  onClick={() => setFormData(prev => ({ ...prev, reminderType: 'scheduled', repeatValue: '[]' }))}
                >
                  One Time
                </button>
                <button
                  type="button"
                  className={`btn ${formData.reminderType === 'repeating' ? 'btn-dark' : 'btn-outline-dark'}`}
                  onClick={() => setFormData(prev => ({ ...prev, reminderType: 'repeating' }))}
                >
                  Weekly
                </button>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col col-sm-3">
              <p className="mb-0">Time</p>
            </div>
            <div className="col-sm-9">
              <select
                className="dndTimeSelector"
                value={timeValues.hour}
                onChange={(e) => handleTimeChange('hour', e.target.value)}
              >
                {hours.map((hour) => (
                  <option key={hour} value={hour}>{hour}</option>
                ))}
              </select>
              <strong>:</strong>
              <select
                style={{ marginLeft: '1em' }}
                className="dndTimeSelector"
                value={timeValues.minute}
                onChange={(e) => handleTimeChange('minute', e.target.value)}
              >
                {minutes.map((minute) => (
                  <option key={minute} value={minute}>{minute}</option>
                ))}
              </select>
              <select
                style={{ marginLeft: '1em' }}
                className="dndTimeSelector"
                value={timeValues.period}
                onChange={(e) => handleTimeChange('period', e.target.value)}
              >
                {periods.map((period) => (
                  <option key={period} value={period}>{period}</option>
                ))}
              </select>
            </div>
          </div>

          {formData.reminderType === 'repeating' && (
            <div className="row mb-4">
              <div className="col col-sm-3">
                <p className="mb-0">Active Days</p>
              </div>
              <div className="col-sm-9">
                {Object.keys(checkedDays).map((day) => (
                  <div key={day} className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={day}
                      checked={checkedDays[day]}
                      onChange={() => handleDayChange(day)}
                    />
                    <label className="form-check-label mr-3" htmlFor={day}>
                      {day.slice(0, 3)}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="row mb-4">
            <div className="col col-sm-3">
              <p className="mb-0">Message</p>
            </div>
            <div className="col-sm-9">
              <textarea
                className="form-control"
                rows="3"
                value={formData.message}
                onChange={(e) => setFormData(prev => ({ ...prev, message: e.target.value }))}
                placeholder="Enter reminder message..."
              />
            </div>
          </div>

          {renderImageUploadSection()}
      
      
          {renderDeleteDialog()}

          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
            <button 
              type="button" 
              className="btn btn-secondary" 
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button 
              type="button" 
              className="btn btn-dark" 
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );

};

export default RemindersTab;