import React, { useState } from 'react';
import { toast } from "react-hot-toast";
import { generateRandomString } from '../header';

const ResetDevice = ({ user, selectedOrg, fetchCwUsers,onBackClick }) => {
  const [showResetConfirmModal, setShowResetConfirmModal] = useState(false);
  const [showUnEnrollConfirmModal, setShowUnEnrollConfirmModal] = useState(false);

  const handleResetDevice = () => {
    setShowResetConfirmModal(true);
  };

  const handleUnEnrollDevice = () => {
    setShowUnEnrollConfirmModal(true);
  };

  const handleResetConfirm = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEVICE_API_URL}/reset_device?device_id=${user.id}`,
        {
          method: "POST",
          headers: {
            "accept": "*/*",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          credentials: "include",
        }
      );
  
      if (response.ok) {
        toast.success("Device reset successfully!");
        // Refresh the device list if needed
        if (fetchCwUsers && selectedOrg) {
          fetchCwUsers(selectedOrg);
        }
        // Close the modal
        setShowResetConfirmModal(false);
        // Optionally navigate back to the device list
        onBackClick();
      } else {
        console.error("Failed to reset device");
        toast.error("Failed to reset device");
      }
    } catch (error) {
      console.error("Error resetting device:", error);
      toast.error("An error occurred while resetting the device");
    }
  };

  const handleUnEnrollConfirm = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEVICE_API_URL}/unlink_device_org?id=${user.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        toast.success("Device successfully unenrolled.");
        if (fetchCwUsers && selectedOrg) {
          fetchCwUsers(selectedOrg);
        }
        onBackClick();
      } else {
        toast.error("Failed to unenroll the device.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while unenrolling the device.");
    }

    setShowUnEnrollConfirmModal(false);
  };

  return (
    <div className="reset-device-container" style={{ padding: "20px" }}>
      <div className="reset-section" style={{ marginBottom: "40px" }}>
        <button
          className="btn cur-p btn-outline-danger"
          style={{ 
            width: "150px", 
            marginBottom: "15px",
            
          }}
          onClick={handleResetDevice}
        >
          Reset device
        </button>
        <p style={{ 
          color: "#666", 
          fontSize: "14px",
          maxWidth: "700px",
          lineHeight: "1.5"
        }}>
          Performs a soft-reset on CareWindow, leaving it enrolled in the Carer Dashboard and connected to the Wi-Fi network. Use this when a resident has left and the device is being assigned to a new user.
        </p>
      </div>

      <div className="unenroll-section">
        <button type='button'
          className="btn cur-p btn-outline-danger"
          style={{ 
            width: "150px", 
            marginBottom: "15px",
            
          }}
          onClick={handleUnEnrollDevice}
        >
          Un-enrol device
        </button>
        <p style={{ 
          color: "#666", 
          fontSize: "14px",
          maxWidth: "700px",
          lineHeight: "1.5"
        }}>
          Removes the device from the Carer Dashboard. Family settings will be left intact.
        </p>
      </div>

      {/* Reset Confirmation Modal */}
      {showResetConfirmModal && (
        <div className="modal" style={{ display: "block" }} role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Reset</h5>
                <button type="button" className="close-button" onClick={() => setShowResetConfirmModal(false)}>
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to reset this device? This will clear all user data while keeping the device enrolled.</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowResetConfirmModal(false)}>
                  Cancel
                </button>
                <button type="button" className="btn btn-primary" onClick={handleResetConfirm}>
                  Confirm Reset
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Un-enroll Confirmation Modal */}
      {showUnEnrollConfirmModal && (
        <div className="modal" style={{ display: "block" }} role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Un-enrollment</h5>
                <button type="button" className="close-button" onClick={() => setShowUnEnrollConfirmModal(false)}>
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to un-enroll this device from the Carer Dashboard?</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowUnEnrollConfirmModal(false)}>
                  Cancel
                </button>
                <button type="button" className="btn btn-danger" onClick={handleUnEnrollConfirm}>
                  Confirm Un-enrollment
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResetDevice;