import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import AgoraRTC from "agora-rtc-sdk-ng";
import { 
  KeyboardVoiceRounded, 
  MicOffRounded, 
  VideocamRounded, 
  VideocamOffRounded 
} from '@mui/icons-material';
import deviceProfile from "../../images/device.png";
import carewindow_blue from '../../images/carewindow_blue.png'
import { Phone as PhoneIcon } from '@mui/icons-material';
import { AccessTime as AccessTimeIcon } from '@mui/icons-material';


const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

function ExtVideoCallPage() {
  const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const [deviceInfo, setDeviceInfo] = useState(null);
  const [decodedData, setDecodedData] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [error, setError] = useState(null);

  // Preview states
  const [previewVideoTrack, setPreviewVideoTrack] = useState(null);
  const [previewAudioTrack, setPreviewAudioTrack] = useState(null);
  const [isPreviewCameraEnabled, setIsPreviewCameraEnabled] = useState(true);
  const [isPreviewMicrophoneEnabled, setIsPreviewMicrophoneEnabled] = useState(true);
  const previewVideoRef = useRef(null);

  // Device states
  const [audioInputDevices, setAudioInputDevices] = useState([]);
  const [audioOutputDevices, setAudioOutputDevices] = useState([]);
  const [videoDevices, setVideoDevices] = useState([]);
  const [selectedAudioInput, setSelectedAudioInput] = useState("");
  const [selectedAudioOutput, setSelectedAudioOutput] = useState("");
  const [selectedCamera, setSelectedCamera] = useState("");
  const [isLinkExpired, setIsLinkExpired] = useState(false);

  const location = useLocation();
  const [userName, setUserName] = useState('');
  const [nameError, setNameError] = useState('');
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(isMobileDevice());
  }, []);

  useEffect(() => {
    const initializeCall = async () => {
      try {
        const searchParams = new URLSearchParams(location.search);
        const encodedData = searchParams.get('data');
        
        if (!encodedData) {
          throw new Error('No data provided in URL');
        }

        const decoded = JSON.parse(atob(encodedData));
        console.log("Decoded data:", decoded); 
        setDecodedData(decoded);

        if (decoded.expiryDate) {
          const expiryDate = new Date(decoded.expiryDate);
          const now = new Date();
          if (now > expiryDate) {
            setIsLinkExpired(true);
            return; 
          }
        }
        
        const response = await fetch(
          `${process.env.REACT_APP_DEVICE_API_URL}/device/${decoded.InviteDeviceId}`,
          {
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
              'portalscope': 'b3NMWOVzfdRUjrW',
              'validatereq': generateRandomString(30)
            }
          }
        );

        if (!response.ok) {
          const errorText = await response.text();
          console.error("API Error Response:", errorText);
          throw new Error(errorText || 'Failed to fetch device information');
        }

        const deviceData = await response.json();
        console.log("Device data:", deviceData);
        setDeviceInfo(deviceData);

      } catch (err) {
        console.error('Initialization error:', err);
        setError(err.message);
      }
    };

    initializeCall();
  }, [location]);

  useEffect(() => {
    const fetchDevices = async () => {

      if (isMobile) {
        
        return;
      }

      try {
        const audioDevices = await AgoraRTC.getMicrophones();
        setAudioInputDevices(audioDevices);
        
        const audioOutput = await AgoraRTC.getPlaybackDevices();
        setAudioOutputDevices(audioOutput);
        
        const cameraDevices = await AgoraRTC.getCameras();
        setVideoDevices(cameraDevices);
      } catch (error) {
        console.error('Error fetching devices:', error);
      }
    };

    fetchDevices();
  }, [isMobile]);

  const cleanupPreviewTracks = async () => {
    if (previewVideoTrack) {
      await previewVideoTrack.setEnabled(false);
      previewVideoTrack.stop();
      previewVideoTrack.close();
      setPreviewVideoTrack(null);
    }
    if (previewAudioTrack) {
      await previewAudioTrack.setEnabled(false);
      previewAudioTrack.stop();
      previewAudioTrack.close();
      setPreviewAudioTrack(null);
    }
  
  };

  const handleNameChange = (event) => {
    setUserName(event.target.value);
    if (event.target.value.trim()) {
      setNameError('');
    }
  };

  const validateName = () => {
    if (!userName.trim()) {
      setNameError('Please enter your name');
      return false;
    }
    return true;
  };


  const handleStartPreview = async () => {
    
    if (!validateName()) {
      return;
    }

    
    setShowPreviewModal(true);
    setIsPreviewCameraEnabled(true);
    setIsPreviewMicrophoneEnabled(true);

    try {

      await navigator.mediaDevices.getUserMedia({ audio: true, video: true });

      let audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      let videoTrack = await AgoraRTC.createCameraVideoTrack();

      setPreviewAudioTrack(audioTrack);
      setPreviewVideoTrack(videoTrack);
      
      if (previewVideoRef.current) {
        videoTrack.play(previewVideoRef.current);
      }
    } catch (error) {
      console.error("Failed to create preview tracks", error);
      setIsPreviewCameraEnabled(false);
      setIsPreviewMicrophoneEnabled(false);
    }
  };

  const handleStartCall = async () => {
    
      setShowPreviewModal(false);
      await cleanupPreviewTracks();
    

    const callData = {
      userInfo: { name: userName.trim() },
      callingDeviceId: decodedData.InviteDeviceId,
      callingAccountId: decodedData.callingAccountId,
      callerName: userName.trim(),
      initialCameraEnabled: isPreviewCameraEnabled,
      initialMicrophoneEnabled: isPreviewMicrophoneEnabled,
      initialAudio: selectedAudioInput,
      initialVideo: selectedCamera,
      initialAudioOutput: selectedAudioOutput,
      isMobileDevice: isMobile,
      answerName: deviceInfo.name,
    };

    

    const encodedCallData = btoa(JSON.stringify(callData));
    if(isMobile){
      window.open(`/video-call?data=${encodedCallData}`, '_self');
    }else{
      window.open(`/video-call?data=${encodedCallData}`, '_blank');
    }
    
  };

  const handlePreviewCancel = async () => {
    setShowPreviewModal(false);
    await cleanupPreviewTracks();
  };

  const togglePreviewCamera = async () => {
    if (previewVideoTrack) {
      await previewVideoTrack.setEnabled(!isPreviewCameraEnabled);
      setIsPreviewCameraEnabled(!isPreviewCameraEnabled);
    }
  };

  const togglePreviewMicrophone = async () => {
    if (previewAudioTrack) {
      await previewAudioTrack.setEnabled(!isPreviewMicrophoneEnabled);
      setIsPreviewMicrophoneEnabled(!isPreviewMicrophoneEnabled);
    }
  };

  const handleDeviceChange = (event, setter) => {
    setter(event.target.value);
  };

  const buttonStyle = (enabled) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "60px",
    height: "60px",
    margin: "0 10px",
    borderRadius: "50%",
    border: "none",
    cursor: "pointer",
    transition: "all 0.3s ease",
    backgroundColor: enabled ? "#1976D2" : "#1976D2",
    boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
    color: "white",
  });

  const renderPreviewModal = () => (
    <div
      className="modal fade show"
      style={{
        display: "block",
        backgroundColor: "rgba(0,0,0,0.5)",
        overflowY: "auto",
        paddingBottom: "20px",
      }}
      aria-modal="true"
      role="dialog"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
        style={{
          maxWidth: isMobile ? "100%" : "90%",
          margin: "auto",
          height: isMobile ? "100vh" : "auto",
        }}
      >
        <div
          className="modal-content"
          style={{
            backgroundColor: "#f0f0f0",
            width: "100%",
            padding: "15px",
            maxHeight: isMobile ? "60vh" : "100vh",
            height: isMobile ? "100%" : "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="modal-header" style={{ flexShrink: 0 }}>
            <h1 className="modal-title" style={{ 
              fontSize: isMobile ? "1.5rem" : "2rem" 
            }}>
              Video Call Preview
            </h1>
            <button
              type="button"
              className="close-button"
              onClick={handlePreviewCancel}
              aria-label="Close"
              style={{
                background: "none",
                border: "none",
                fontSize: "1.5rem",
                cursor: "pointer",
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
            className="modal-body"
            style={{
              padding: "15px",
              overflowY: "auto",
              flexGrow: isMobile ? 0 : 1, 
              display: "flex",
              flexDirection: "column",
              justifyContent: isMobile ? "flex-start" : "space-between"
            }}
          >
            <div
              style={{
                width: "100%",
                aspectRatio: "16/9",
                backgroundColor: "black",
                position: "relative",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <div ref={previewVideoRef} style={{ width: "100%", height: "100%" }} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
              }}
            >
              <button onClick={togglePreviewMicrophone} style={buttonStyle(isPreviewMicrophoneEnabled)}>
                {isPreviewMicrophoneEnabled ? <KeyboardVoiceRounded /> : <MicOffRounded />}
              </button>
              <button onClick={togglePreviewCamera} style={buttonStyle(isPreviewCameraEnabled)}>
                {isPreviewCameraEnabled ? <VideocamRounded /> : <VideocamOffRounded />}
              </button>
            </div>
            
            
            {!isMobile && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "15px",
                }}
              >
                <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "15px",
            }}
          >
            <div style={{ width: "100%", maxWidth: "350px", marginBottom: "10px" }}>
              <label htmlFor="audio-input-select" style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>
                Microphone
              </label>
              <select
                id="audio-input-select"
                value={selectedAudioInput}
                onChange={(e) => handleDeviceChange(e, setSelectedAudioInput)}
                style={{
                  width: "100%",
                  padding: "8px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  fontSize: "14px",
                }}
              >
                <option value="">
                  System Default - {audioInputDevices[0]?.label || "No Device"}
                </option>
                {audioInputDevices.map((device) => (
                  <option key={device.deviceId} value={device.deviceId}>
                    {device.label || "Unnamed Audio Input"}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ width: "100%", maxWidth: "350px", marginBottom: "10px" }}>
              <label htmlFor="audio-output-select" style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>
                Speaker
              </label>
              <select
                id="audio-output-select"
                value={selectedAudioOutput}
                onChange={(e) => handleDeviceChange(e, setSelectedAudioOutput)}
                style={{
                  width: "100%",
                  padding: "8px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  fontSize: "14px",
                }}
              >
                <option value="">
                  System Default - {audioOutputDevices[0]?.label || "No Device"}
                </option>
                {audioOutputDevices.map((device) => (
                  <option key={device.deviceId} value={device.deviceId}>
                    {device.label || "Unnamed Audio Output"}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ width: "100%", maxWidth: "350px" }}>
              <label htmlFor="camera-select" style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>
                Camera
              </label>
              <select
                id="camera-select"
                value={selectedCamera}
                onChange={(e) => handleDeviceChange(e, setSelectedCamera)}
                style={{
                  width: "100%",
                  padding: "8px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  fontSize: "14px",
                }}
              >
                <option value="">
                  System Default - {videoDevices[0]?.label || "No Device"}
                </option>
                {videoDevices.map((device) => (
                  <option key={device.deviceId} value={device.deviceId}>
                    {device.label || "Unnamed Camera"}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
              
            )}
          </div>
          <div
            className="modal-footer"
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexShrink: 0,
              padding: isMobile ? "10px" : "15px",
            }}
          >
            <button 
              type="button" 
              className="btn btn-secondary" 
              onClick={handlePreviewCancel}
              style={{
                padding: isMobile ? "8px 16px" : "12px 20px",
                fontSize: isMobile ? "14px" : "16px",
              }}
            >
              Cancel
            </button>
            <button 
              type="button" 
              className="btn btn-primary" 
              onClick={handleStartCall}
              style={{
                padding: isMobile ? "8px 16px" : "12px 20px",
                fontSize: isMobile ? "14px" : "16px",
              }}
            >
              Start Call
            </button>
          </div>
        </div>
      </div>
    </div>
  );


  return (
    <div style={{ 
      width: '100vw', 
      height: '100vh', 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center',
      backgroundColor: '#f5f5f5'
    }}>
      <div style={{
        width: '100%',
        maxWidth: '400px',
        padding: '2rem',
        backgroundColor: 'white',
        borderRadius: '16px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        textAlign: 'center'
      }}>
        <img className="login-carewindow" width="300" src={carewindow_blue} alt="CareWindow" />
        
        {error ? (
          
          <>
          {console.log(error)}
            <div style={{
              width: '120px',
              height: '120px',
              margin: '1rem auto 1.5rem',
              borderRadius: '60px',
              overflow: 'hidden',
              border: '3px solid #e0e0e0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#fff5f5'
            }}>
              <span style={{ 
                fontSize: '40px', 
                color: '#dc2626'
              }}>!</span>
            </div>
            <h2 style={{
              fontSize: '1.5rem',
              fontWeight: 'bold',
              color: '#dc2626',
              marginBottom: '1rem'
            }}>An Error Occurred<br/> Please Try Again Later</h2>
            {/* <p style={{
              color: '#666',
              marginBottom: '2rem'
            }}>{error}</p> */}
          </>
        ) : isLinkExpired ? (
          <>
            <div style={{
              width: '120px',
              height: '120px',
              margin: '1rem auto 1.5rem',
              borderRadius: '60px',
              overflow: 'hidden',
              border: '3px solid #e0e0e0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#fff5f5'
            }}>
              <AccessTimeIcon style={{ 
                fontSize: '40px', 
                color: '#dc2626'
              }} />
            </div>
            <h2 style={{
              fontSize: '1.5rem',
              fontWeight: 'bold',
              color: '#dc2626',
              marginBottom: '1rem'
            }}>Link Expired</h2>
            <p style={{
              color: '#666',
              marginBottom: '1rem'
            }}>
              This temporary link has expired. Please request a new link to continue.
            </p>
            <button
              onClick={() => window.close()}
              style={{
                width: '100%',
                padding: '12px',
                backgroundColor: '#1976D2',
                color: 'white',
                border: 'none',
                borderRadius: '8px',
                fontSize: '1rem',
                fontWeight: '500',
                cursor: 'pointer',
                transition: 'background-color 0.2s ease',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
              }}
              onMouseOver={e => e.currentTarget.style.backgroundColor = '#1565C0'}
              onMouseOut={e => e.currentTarget.style.backgroundColor = '#1976D2'}
            >
              Close Window
            </button>
          </>
        ) :  !deviceInfo ? (
          // Loading state
          <>
            <div style={{
              width: '120px',
              height: '120px',
              margin: '1rem auto 1.5rem',
              borderRadius: '60px',
              overflow: 'hidden',
              border: '3px solid #e0e0e0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative'
            }}>
              <div style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                border: '3px solid transparent',
                borderTopColor: '#1976D2',
                animation: 'spin 1s linear infinite'
              }} />
              <PhoneIcon style={{ 
                fontSize: '40px',
                color: '#1976D2'
              }} />
            </div>
            <h2 style={{
              fontSize: '1.5rem',
              fontWeight: 'bold',
              color: '#333',
              marginBottom: '2rem'
            }}>Loading·····</h2>
          </>
        ) : (
          // Normal state with device info
          <>
            <div style={{
              width: '120px',
              height: '120px',
              margin: '1rem auto 1.5rem',
              borderRadius: '60px',
              overflow: 'hidden',
              border: '3px solid #e0e0e0'
            }}>
              {deviceInfo.profilePicture ? (
                <img
                  src={`data:image/png;base64,${deviceInfo.profilePicture}`}
                  alt={deviceInfo.name}
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              ) : (
                <img
                  src={deviceProfile}
                  alt="Default profile"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              )}
            </div>
            <h2 style={{
              fontSize: '1.5rem',
              fontWeight: 'bold',
              color: '#333',
              marginBottom: '2rem'
            }}>{deviceInfo.name}</h2>

            <div style={{ marginBottom: '2rem' }}>
              <input
                type="text"
                value={userName}
                onChange={handleNameChange}
                placeholder="Enter your name"
                style={{
                  width: '100%',
                  padding: '12px',
                  border: `1px solid ${nameError ? '#dc2626' : '#e0e0e0'}`,
                  borderRadius: '8px',
                  fontSize: '1rem',
                  marginBottom: '0.5rem'
                }}
              />
              {nameError && (
                <span style={{ 
                  color: '#dc2626', 
                  fontSize: '0.875rem', 
                  display: 'block', 
                  marginTop: '-0.25rem' 
                }}>
                  {nameError}
                </span>
              )}
            </div>

            <button
              onClick={handleStartPreview}
              style={{
                width: '100%',
                padding: '12px',
                backgroundColor: '#1976D2',
                color: 'white',
                border: 'none',
                borderRadius: '8px',
                fontSize: '1rem',
                fontWeight: '500',
                cursor: 'pointer',
                transition: 'background-color 0.2s ease',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
              }}
              onMouseOver={e => e.currentTarget.style.backgroundColor = '#1565C0'}
              onMouseOut={e => e.currentTarget.style.backgroundColor = '#1976D2'}
            >
              Start Call
            </button>
          </>
        )}
      </div>

      {showPreviewModal && renderPreviewModal()}
    </div>
  );
}

export default ExtVideoCallPage;