import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import AgoraRTC from "agora-rtc-sdk-ng";
import AgoraVideoCall from './AgoraVideoCall';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';




function VideoCallPage() {
  const [callData, setCallData] = useState(null);
  const [localTracks, setLocalTracks] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  
  useEffect(() => {
    setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
  }, []);
   


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const encodedData = searchParams.get('data');
    const callType = searchParams.get('type'); 
    if (encodedData) {
      try {
        const decodedData = JSON.parse(atob(encodedData));
        setCallData({
          ...decodedData,
          isEventCall: callType === 'event',
          isGroupCall: callType === 'group'
        });
        console.log(decodedData);


        console.log(decodedData.initialAudio);
        initLocalTracks(decodedData.initialCameraEnabled, decodedData.initialMicrophoneEnabled, decodedData.initialAudio, decodedData.initialVideo);

      }
      catch (error) {
        console.error('Failed to decode call data:', error);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }



    return () => {
      if (localTracks) {
        localTracks.audioTrack.stop();
        localTracks.audioTrack.close();
        localTracks.videoTrack.stop();
        localTracks.videoTrack.close();
      }
    };
  }, [location]);


  const initLocalTracks = async (cameraEnabled, microphoneEnabled, audioId, videoId) => {
    try {
      if (isMobile) {
        // 先试图获取一次权限
        try {
          const existingStreams = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: true
          });
          existingStreams.getTracks().forEach(track => track.stop());
          await new Promise(resolve => setTimeout(resolve, 500));
        } catch (err) {
          console.error("Failed to get media permissions:", err);
          throw err;
        }
      }


      let audioTrack;
      let videoTrack;


      if (isMobile) {
        
        audioTrack = await AgoraRTC.createMicrophoneAudioTrack({
          encoderConfig: "music_standard",
          microphoneId: audioId || undefined
        });

        videoTrack = await AgoraRTC.createCameraVideoTrack({
          facingMode: "user"
        });

      

        audioTrack.on("track-ended", () => {
          console.log("mobile audio track ended");
        });
        videoTrack.on("track-ended", () => {
          console.log("mobile video track ended");
        });
  
        console.log("Mobile tracks created:", { audioTrack, videoTrack });

        if (!audioTrack) {
          audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        }
        if (!videoTrack) {
          videoTrack = await AgoraRTC.createCameraVideoTrack();
        }
        
      } else {
        // PC 端保持原有逻辑
        if (audioId != null) {
          audioTrack = await AgoraRTC.createMicrophoneAudioTrack({ microphoneId: audioId });
        } else {
          audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        }
        if (videoId != null) {
          videoTrack = await AgoraRTC.createCameraVideoTrack({ cameraId: videoId });
        } else {
          videoTrack = await AgoraRTC.createCameraVideoTrack();
        }
      }

      await audioTrack.setEnabled(microphoneEnabled);
      await videoTrack.setEnabled(cameraEnabled);

      setLocalTracks({ audioTrack, videoTrack });
      console.log("Tracks initialized and enabled:", {
        audio: microphoneEnabled,
        video: cameraEnabled
      });
      setIsLoading(false);
    } catch (error) {
      console.error('Failed to create local tracks:', error);
      setIsLoading(false);
    }
  };


  if (isLoading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        bgcolor="black"
      >
        <CircularProgress color="primary" size={60} thickness={4} />
        <Typography color="primary" style={{ marginTop: '20px' }}>
          Loading...
        </Typography>
      </Box>
    );
  }

  if (!callData || !localTracks) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        bgcolor="black"
        color="white"
        fontSize="1.2rem"
      >
        Failed to initialize video call. Please try again.
      </Box>
    );
  }

  return (
    <div style={{ width: '100vw', height: '100vh', backgroundColor: 'black' }}>
      <AgoraVideoCall

        userInfo={callData.userInfo}
        callingDeviceId={callData.callingDeviceId}
        callingAccountId={callData.callingAccountId}
        callerName={callData.callerName}
        policyId={callData.policyId} 
        isGroupCall={callData.isGroupCall}
        eventId={callData.eventId}
        isEventCall={callData.isEventCall}
        isJoiningEvent={callData.isJoiningEvent}
        onClose={() => window.close()}
        localVideoTrack={localTracks.videoTrack}
        localAudioTrack={localTracks.audioTrack}
        initialCameraEnabled={callData.initialCameraEnabled}
        initialMicrophoneEnabled={callData.initialMicrophoneEnabled}
        audiosource={callData.initialAudio}
        videosource={callData.initialVideo}
        audioOutput={callData.initialAudioOutput}
        answerName={callData.answerName}
      />

    </div>
  );
}

export default VideoCallPage;


